import React, { useState } from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import ResultFallback from "../components/Result/Result"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import HeroBg from "../images/internal_header.jpeg"
import Seo from "../components/seo"

import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
function Result() {
  const [open, setOpen] = useState(false)

  if (typeof window !== "undefined") {
    var { search } = window.location
  }

  const query = new URLSearchParams(search).get("s")
  const data = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)
  const { index, store } = data.localSearchPages
  const results = useFlexSearch(query, index, store)
  return (
    <ApolloProvider client={client}>
      <Layout>
        <div className="search-result-page">
          <div
            className="result-hero"
            style={{ backgroundImage: `url(${HeroBg})` }}
          >
            <div className="container">
              <div
                className="callback-button"
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <p>Request a callback today »</p>
              </div>
              <div className="title-wrapper">
                <h1>Search result</h1>
              </div>
            </div>
            <CallbackForm open={open} setOpen={setOpen} />
          </div>
          <div className="container">
            <div className="result-wrapper">
              <p className="result-query">Results for "{query}"</p>
              <div className="posts">
                {results.map(post => {
                  return <ResultFallback post={post} />
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}

export const Head = () => (
  <Seo title="Search result - Wirehouse Employer Services" />
)

export default Result
