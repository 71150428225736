import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Image from "../../helper/Image"
import "../../components/Post/post.scss"
import parse from "html-react-parser"
function Result({ post }) {
  return (
    <article className="post">
      <div className="post-image">
        <Link to={post?.uri}>
          {post?.nodeType === "Post" ? (
            post.featuredImage ? (
              <Image image={post.featuredImage?.node} alt="Post image" />
            ) : (
              <StaticImage
                src="../../images/result-fallback.jpeg"
                alt="Fallback image"
              />
            )
          ) : (
            <StaticImage
              src="../../images/result-fallback.jpeg"
              alt="Fallback image"
            />
          )}
        </Link>
      </div>
      <div className="entry-title">
        <h2>
          <Link to={post?.uri}>{post?.title}</Link>
        </h2>
        <p className="post-meta">{post?.date}</p>
        <div className="post-content">
          {post?.excerpt ? (
            <div className="post-excerpt">{parse(post?.excerpt)}</div>
          ) : (
            <div className="post-excerpt">
              <p>Wirehouse page</p>
            </div>
          )}
          <Link className="read-more" to={post?.uri}>
            Read more
          </Link>
        </div>
      </div>
    </article>
  )
}

export default Result
